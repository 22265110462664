html {
    overflow-x: hidden;
}

.container {
    width: 100%;
}

.img-list {
    height: 37vh;
    overflow-y: scroll;
}

#stage-container > div > div > div > canvas {
    border: 1px solid black;
}

.konvajs-content > canvas {
    border: 1px solid black !important;
}

.editor-header {
    position:  relative !important;
}
  
.sidebar {
    height: 100%;
}

.number-box-width {
    width: 35px !important;
}

.number-box-width::-webkit-outer-spin-button,
.number-box-width::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.number-box-width[type=number] {
  -moz-appearance: textfield;
}

#canvas canvas {
border: 1px solid black !important;
background-color: white !important;
}